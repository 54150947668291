export default () => ({
    list: {},
    code: "+420",
    selected: "CZ",
    get isCustom() {
        return this.selected === 'custom'
    },
    init() {
        this.list = phoneCodesList ?? {}
        this.code = Object.values(this.list)[0] ?? "+420"
        this.selected = Object.keys(this.list)[0] ?? "CZ"
    },
    async changePhoneCode() {
        this.selected = this.$el.dataset.key
        this.code = !this.isCustom ? this.$el.dataset.code : ""

        let parent = this.$el.closest('details') as HTMLElement
        if (!this.isCustom) parent = parent.parentElement

        this.$nextTick(() => {
            (parent.nextElementSibling as HTMLInputElement).focus()
        })
    },
    closeDropdown(e: FocusEvent) {
        const details = (e.target as HTMLElement).closest('details')
        if (!details.contains(e.relatedTarget as Node)) details.open = false
    },
    codeInput: {
        ['x-show']() { return this.isCustom },
        ['x-mask:dynamic']() { return '+999' },
        [':value']() { return this.code }
    },
    phoneInput: {
        ['x-mask:dynamic']() {
            return '9999999999999'
        },
        ['@input']() {
            if (this.$el.value.length < 3 || !this.$el.value.startsWith("+")) return

            let matchingCode = false
            Object.values(this.list).forEach((code: string, index) => {
                if (this.$el.value.startsWith(code)) {
                    this.code = code
                    this.selected = Object.keys(this.list)[index]
                    matchingCode = true
                }
            })

            if (!matchingCode && this.$el.value.length > 3) {
                this.selected = 'custom'
                this.code = ""
            }
        }
    }
})