import Alpine from "alpinejs"

export const CompareLinkToggle = (offerId) => ({
    id: offerId,
    get isActive() { return Alpine.store('Collections').includes(this.id, 'compare') },
    toggle: {
        [':class']() { return this.isActive && 'is-active' },
        ['@click.prevent']() {
            const targetUrl = this.$el.dataset.url ?? null
            this.isActive && targetUrl ? window.location.assign(targetUrl)
                : Alpine.store('Collections').set(this.id, 'compare')
        }
    },
    label: {
        ['x-text']() { return this.isActive ? this.$el.dataset.link : this.$el.dataset.label },
    }
})

export const CompareTable = () => ({
    filterUrl: Alpine.$persist('').as('offersFilterUrl').using(sessionStorage),
    hideSame: Alpine.$persist(false).as('compareTable-hideSame'),
    order: Alpine.$persist('ASC').as('compareTable-order'),
    orderBy: Alpine.$persist(null).as('compareTable-orderBy'),
    items: [],
    orderedItems: [],
    orderItems() {
        if (!this.orderBy) {
            this.orderedItems = [...this.items]
            return;
        }

        const sortArray = [...this.items].map(item => {
            return { ...item, compareValue: this.orderBy === 'price' ? item.price : item.parameters[this.orderBy] }
        }).sort((a, b) => (a.compareValue > b.compareValue)
            ? 1 : (a.compareValue === b.compareValue) ? ((a.ID > b.ID) ? 1 : -1) : -1 )

        if (this.order === 'DESC') sortArray.reverse()

        this.orderedItems = sortArray
    },

    get _mode() { return window.location.search.includes('nabidky') ? 'share' : 'collection' },
    get length() { return this.items.length },
    get parameters() { return this.length ? Object.keys(this.items[0].parameters ?? {}) : [] },
    get sameRows() { return this.length > 1 ? this.parameters.filter(parameter => {
        return this.items.map(item => item.parameters[parameter])
            .every((val, i, arr) => val === arr[0])
    }) : [] },
    init() {
        this.items = compareData ?? []
        Alpine.store('Collections').compare?.forEach(collectionItem => {
            !this.items.find(item => item.ID === collectionItem)
                && Alpine.store('Collections').set(collectionItem, 'compare')
        })

        this.orderItems()
        this.watchScrollProgress()
    },
    watchScrollProgress() {
        this._atStart = this.$refs.header.scrollLeft <= this.itemWidth / 4
        this._atEnd = this.$refs.header.scrollLeft + this.$refs.header.clientWidth >= this.$refs.header.scrollWidth - 40
    },

    _windowWidth: document.body.offsetWidth,
    _rowsOffset: null,
    _preventEvent: false,
    _atStart: false,
    _atEnd: false,
    get itemWidth() { return this.$refs.header.children[0]?.offsetWidth ?? 0 },
    syncScroll(e, target) {
        if (this._preventEvent) {
            this._preventEvent = false
            return
        }

        this._preventEvent = true
        e.target.classList.add('is-scrolling')
        this.$refs[target].classList.remove('is-scrolling')
        this.$refs[target].scrollLeft = e.target.scrollLeft

        this.watchScrollProgress()
    },
    scrollEntries(direction) {
        this.$refs.rows.scrollBy({
            top: 0,
            left: direction === 'next' ? this.itemWidth : this.itemWidth * -1,
            behavior: 'smooth'
        });
    },
    setOrder(parameter) {
        if (this.orderBy === parameter && this.order === 'DESC') {
            this.order = 'ASC'
            this.orderBy = null
        } else if (this.orderBy === parameter) {
            this.order = 'DESC'
        } else {
            this.order = 'ASC'
            this.orderBy = parameter
        }

        this.orderItems()
    },

    totalCount: {
        ['x-show']() { return this.length > 1 },
        ['x-text']() { return this.length }
    },
    table: {
        ['x-show']() { return this.length },
        [':style']() { return this.length ? {
            '--ViewportWidth': `${this._windowWidth}px`,
            '--CompareTableLeftOffset': `${this._rowsOffset ?? this.$el.getBoundingClientRect()?.left ?? 0}px`
        } : {} },
        ['@resize.window']() {
            this._windowWidth = document.body.offsetWidth
            this._rowsOffset = this.length ? this.$el.getBoundingClientRect()?.left ?? 0 : 0

            this.watchScrollProgress()
        }
    },
    header: {
        ['@scroll'](e) { this.syncScroll(e, 'rows') },
        ['@scroll.window.passive']() {
            const headerOffset = document.body.classList.contains('is-sticked-header') ? document.querySelector('[data-app-header]')?.offsetHeight ?? 0 : 0
            this.$el.parentNode.classList.toggle('is-sticked', this.$el.getBoundingClientRect().top <= (document.body.offsetWidth >= 1280 ? -200 : -154) + headerOffset)
        }
    },
    rows: {
        ['@scroll'](e) { this.syncScroll(e, 'header') }
    },
    item: {
        [':style']() { return this.orderBy ? { 'order': this.orderedItems.findIndex(item => item.ID === parseInt(this.$el.dataset.id)) } : {} },
        ['x-show']() { return this.items.find(item => item.ID === parseInt(this.$el.dataset.id)) ?? false }
    },
    rowParameter: {
        [':class']() { return this.parameter.startsWith('#') && 'OffersCompareRow-parameterCategory OffersCompareRow--colored' },
        [':data-parameter-index']() { return this.index }
    },
    row: {
        ['x-show']() { return !this.hideSame || !this.sameRows.includes(this.parameter) || this.parameter.startsWith('#') },
        [':class']() { return {
            'OffersCompareRow--featured': !this.sameRows.includes(this.parameter),
            'OffersCompareRow--category': this.parameter.startsWith('#')
        }}
    },
    rowValue: {
        [':class']() { return {
            'OffersCompareRow-valueCategory': this.parameter.startsWith('#'),
            'is-long': ['Barva'].includes(this.parameter)
        }},
        ['x-html']() {
            return '<span class="OffersCompareRow-parameter OffersCompareRow-valueParameter">' + this.parameter.replace('#', '') + '</span>' +
                '<span class="OffersCompareRow-valueText">' + (typeof this.item.parameters[this.parameter] === 'string'
                    ? this.item.parameters[this.parameter] : this.item.parameters[this.parameter] === 1
                        ? this.valueYesHtml : this.valueNoHtml) + '</span>'
        }
    },
    orderButton: {
        ['@click.prevent']() { this.setOrder(this.$el.dataset.parameter) },
        [':class']() { return this.orderBy === this.$el.dataset.parameter && 'is-active' },
        ['x-show']() { return !this.sameRows.includes(this.parameter) },
        ['x-html']() {
            return '<svg height="20" class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><use href="#ikona-razeni" /></svg>'
                + (this.orderBy === this.$el.dataset.parameter ? '<svg height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor"><use href="#ikona-razeni-' + (this.order === 'ASC' ? 'nahoru' : 'dolu') + '" /></svg>' : '');
        }
    },
    hideSameToggle: {
        [':disabled']() { return this.length < 2 }
    },
    prevBtn: {
        [':class']() { return { 'is-disabled': this._atStart }},
        ['@click.prevent']() { this.scrollEntries('prev') }
    },
    nextBtn: {
        ['@click.prevent']() { this.scrollEntries('next') },
        [':class']() { return { 'is-disabled': this._atEnd }}
    },
    removeItem: {
        ['@click.prevent']() {
            this.items = this.items.filter(item => item.ID !== parseInt(this.$el.dataset.id))
            this.orderItems()

            this._mode !== 'share' ?
                Alpine.store('Collections').set(parseInt(this.$el.dataset.id), 'compare') : this.changeUrl()
        }
    },
    removeAllItems: {
        ['@click.prevent']() {
            this.items = []
            this._mode !== 'share' ? Alpine.store('Collections').clear('compare') : this.changeUrl()
        }
    },
    shareCompareLink: {
        ['@click.prevent']() {
            const url = `${window.location.origin}${window.location.pathname}?${this.collectionQuery}`;
            navigator.clipboard.writeText(url).then(() => {
                this.$el.classList.add('is-copied')
                setTimeout(() => this.$el.classList.remove('is-copied'), 20000)
            }, () => {
                console.error('Nepovedlo se zapsat URL do clipboardu. :-(')
            })
        }
    },

    get valueYesHtml() {
        return '<span class="OffersCompareRow-valueState"><svg class="icon-yes" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><use href="#ikona-ano" /></svg> <span>ANO</span></span>'
    },
    get valueNoHtml() {
        return '<span class="OffersCompareRow-valueState"><svg class="icon-no" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><use href="#ikona-ne" /></svg> <span>NE</span></span>'
    },

    get collectionQuery() {
        const itemsID = this.items.reduce((stack, item) => [...stack, item.ID], [])
        const query = new URLSearchParams({ 'nabidky': itemsID.join(',') })
        return decodeURIComponent(query.toString())
    },
    changeUrl() {
        window.history.replaceState(null, null, `?${this.collectionQuery}`);
    }
})

export const CompareToggle = {
    ':class'() { return Alpine.store('Collections').includes(parseInt(this.$el.dataset.id), 'compare') && 'is-active' },
    '@click.prevent'() {
        Alpine.store('Collections').set(parseInt(this.$el.dataset.id), 'compare')
        Alpine.store('Collections').includes(parseInt(this.$el.dataset.id), 'compare')
            && this.$tooltip(document.querySelector('[data-compare-tooltip]')?.innerHTML, {
                timeout: 4000,
                allowHTML: true,
                interactive: true,
                appendTo: () => document.querySelector('main'),
                zIndex: 4
            })
    }
}