import Alpine from "alpinejs"
import {getCookie, setCookie} from "../utils/Cookies";

export const Collections = {
    favorite: [],
    compare: [],

    includes(item, collection) {
        return this[collection].includes(item)
    },
    set(item, collection) {
        this[this.includes(item, collection) ? 'remove' : 'add'](item, collection)
        setCookie(`Collection-${collection}`, this[collection].join(';'), 365)
    },
    add(item, collection) {
        this[collection].push(item)
    },
    remove(item, collection) {
        this[collection] = this[collection].filter(e => e !== item)
    },
    clear(collection) {
        this[collection] = []
        setCookie(`Collection-${collection}`, [], 365)
    },

    init() {
        if (getCookie('Collection-favorite') !== '')
            this.favorite = getCookie('Collection-favorite').split(';').map(Number)

        if (getCookie('Collection-compare') !== '')
            this.compare = getCookie('Collection-compare').split(';').map(Number)
    }
}

export const CollectionLink = (collection: string, clearLabel: string) => ({
    link: {
        [':class']() { return { 'is-empty': !Alpine.store('Collections')[collection].length } },
    },
    count: {
        ['x-text']() { return `(${ Alpine.store('Collections')[collection].length })` }
    },
    clearBtn: {
        ['x-show']() { return Alpine.store('Collections')[collection].length > 0 },
        ['x-tooltip.raw']() { return clearLabel ?? 'Vyprázdnit seznam' },
        ['@click.prevent']() {
            Alpine.store('Collections').clear(collection)
        }
    }
})