export default () => ({
    data: null,
    rada: '',
    model: '',
    init() {
        this.data = testDriveModels ?? {}

        const model = (new URLSearchParams(window.location.search ?? {})).get('model');
        model && Object.entries(this.data).forEach(([brand, models]) => {
            if (this.rada === '' && models.hasOwnProperty(model)) {
                this.rada = brand
                setTimeout(() => { this.model = model }, 240)
            }
        })

        this.$watch('rada', () => { this.model = '' })
        this.$watch('model', () => {
            const queryString = this.model ? `?model=${this.model}` : '';
            history.replaceState(null, '', `${location.pathname}${queryString}`);
        })
    },
    get selectedModel() {
        return this.data?.[this.rada]?.[this.model] ?? null
    },

    modelDefaultOption: {
        ['x-text']() { return this.rada ? this.$el.dataset.emptyLabel : this.$el.dataset.disabledLabel }
    },
    selectedModelPhoto: {
        [':style']() { return 'max-width: min(40rem, 100%)' },
        [':src']() { return this.selectedModel?.photo ?? '' },
        [':alt']() { return this.selectedModel?.title ?? '' }
    }
})