import Alpine from "alpinejs";

export default function() {
    document.addEventListener('alpine:init', () => {
        Alpine.bind('AppPrintButton', { async '@click.prevent'() {
            if (!document.body.classList.contains('is-safari')) {
                window.print()
                return
            }

            // Remove lazy loading from all images before printing
            const images = Array.from(document.querySelectorAll(".OfferHeroPhoto img[loading], [data-gallery-swiper] img[loading]"))
            document.body.classList.add('is-printing')

            setTimeout(async () => {
                await Promise.all(images.map(async (img) => {
                    const src = img.getAttribute("src")

                    return new Promise(res => {
                        if (img.complete) return res()
                        img.onload = img.onerror = () => res()

                        img.removeAttribute("loading")
                        img.src = src
                    }).finally(() => img.scrollIntoView())
                }))

                setTimeout(() => window.print(), 400)
                setTimeout(() => {
                    document.body.classList.contains('is-printing') && window.scrollTo(0, 0)
                    document.body.classList.remove('is-printing')
                }, 2000)
            }, 240)
        } })
    })

    window.addEventListener("afterprint", () => {
        document.body.classList.contains('is-printing') && window.scrollTo(0, 0)
        document.body.classList.remove('is-printing')
    })
}
