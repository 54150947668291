export const TeamVCardCode = {
    'x-tooltip'() {
        return {
            content: this.$el.querySelector('template')?.innerHTML,
            allowHTML: true,
            interactive: true,
            border: 8,
            theme: 'light',
            onShow(instance) {
                window.hasOwnProperty('dataLayer') && dataLayer?.push({
                    event: 'Zobrazení QR vizitky prodejce',
                    name: instance.reference.closest('.OfferSellerCard').querySelector('.OfferSellerCard-name')?.innerText ?? ''
                })
            }
        }
    }
}

export const TeamVCardDownload = {
    '@click'() {
        if (this.$el.classList.contains('is-downloaded'))
            return

        this.$el.classList.add('is-downloaded')
        fetch(`/wp-json/team/vcard/${this.$el.dataset.id}`)
            .then(res => res.json())
            .then((data: { vcard: string, name: string }) => {
                if (!data.vcard) return

                const cardFile = new File([data.vcard], data.name, { type: "text/vcard" })

                const link = document.createElement("a")
                link.href = window.URL.createObjectURL(cardFile)
                link.download = data.name
                link.click()

                window.hasOwnProperty('dataLayer') && dataLayer?.push({
                    event: 'Stažení QR vizitky prodejce',
                    name: this.$el?.closest('.OfferSellerCard').querySelector('.OfferSellerCard-name')?.innerText ?? ''
                })
            })
            .catch(e => {
                this.$el.classList.remove('is-downloaded')
                console.error(e)
            });
    }
}

export const TeamMemberContact = {
    '@click'() {
        if (!window.hasOwnProperty('dataLayer')) return

        dataLayer?.push({
            event: 'Proklik kontaktu prodejce',
            name: this.$el?.closest('.OfferSellerCard').querySelector('.OfferSellerCard-name')?.innerText ?? '',
            type: this.$el?.href?.startsWith('tel') ? 'phone' : 'email',
            contact: this.$el?.innerText ?? ''
        })
    }
}