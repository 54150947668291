export const EntriesGrid = () => ({
    firstEntry: true,
    lastEntry: false,
    itemWidth: 0,
    init() {
        this.itemWidth = this.$refs.grid.children[0].offsetWidth;
        this.watchEntries();
    },
    scrollEntries(direction) {
        this.$refs.grid.scrollBy({
            top: 0,
            left: direction === 'next' ? this.itemWidth : this.itemWidth * -1,
            behavior: 'smooth'
        });
    },
    watchEntries() {
        this.firstEntry = this.$refs.grid.scrollLeft <= 0;
        this.lastEntry = this.$refs.grid.scrollLeft + this.$refs.grid.clientWidth >= this.$refs.grid.scrollWidth - 40;
    },
    nextBtn: {
        [':class']() { return { 'is-disabled': this.lastEntry }},
        ['@click']() {
            this.scrollEntries('next');
        }
    },
    prevBtn: {
        [':class']() { return { 'is-disabled': this.firstEntry }},
        ['@click']() {
            this.scrollEntries('prev');
        }
    },
})