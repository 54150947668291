import Alpine from "alpinejs";
import {
    Archive,
    ArchiveCategoryNav,
    ArchiveFilterOpener,
    ArchiveFilterRange,
    ArchiveFilterRangeSelect, ArchiveFilterSelect,
    ArchiveFilterSelection, ArchiveFilterTerms,
    ArchiveGrid,
    ArchiveLoadMore,
    ArchiveNoResults,
    ArchiveOrder,
    ArchivePager,
    ArchivePagerLink,
    ArchiveQuickLink,
    ArchiveResetFilter,
    ArchiveStickyBar,
    ArchiveTagSelect,
    ArchiveTotalCount
} from "./components/Archive";

export default function () {
    document.addEventListener('alpine:init', () => {
        Alpine.data('ArchiveFilterTerms', ArchiveFilterTerms)
        Alpine.data('ArchiveFilterSelect', ArchiveFilterSelect)
        Alpine.data('ArchiveFilterRange', ArchiveFilterRange)
        Alpine.data('ArchiveFilterRangeSelect', ArchiveFilterRangeSelect)
        Alpine.data('ArchiveFilterSelection', ArchiveFilterSelection)
        Alpine.data('ArchiveCategoryNav', ArchiveCategoryNav)

        Alpine.store('Archive', Archive)

        Alpine.bind('ArchiveFilterOpener', ArchiveFilterOpener)
        Alpine.bind('ArchiveGrid', ArchiveGrid)
        Alpine.bind('ArchiveNoResults', ArchiveNoResults)
        Alpine.bind('ArchiveTotalCount', ArchiveTotalCount)
        Alpine.bind('ArchiveResetFilter', ArchiveResetFilter)
        Alpine.bind('ArchiveTagSelect', ArchiveTagSelect)
        Alpine.bind('ArchiveQuickLink', ArchiveQuickLink)
        Alpine.bind('ArchiveStickyBar', ArchiveStickyBar)
        Alpine.bind('ArchiveOrder', ArchiveOrder)
        Alpine.bind('ArchivePager', ArchivePager)
        Alpine.bind('ArchivePagerLink', ArchivePagerLink)
        Alpine.bind('ArchiveLoadMore', ArchiveLoadMore)
    });

    (() => { // Sticky archive filter
        const stickyFilter = document.querySelector(`form[data-catalog-filter]`) as HTMLElement
        if (!stickyFilter || document.body.classList.contains('is-mobile')) return

        const gap = 32,
            header = document.querySelector('[data-app-header]')?.children[0] as HTMLElement

        let endScroll = window.innerHeight - stickyFilter.offsetHeight - 500,
            currPos = window.scrollY,
            screenHeight = window.innerHeight,
            stickyElementHeight = stickyFilter.offsetHeight,
            topGap = gap + 56,
            headerOffset = 0

        stickyFilter.style.top = `${topGap}px`

        const positionStickySidebar = () => {
            endScroll = window.innerHeight - stickyFilter.offsetHeight - gap
            headerOffset = document.body.classList.contains('is-sticked-header') ? header?.offsetHeight ?? 0 : 0

            let stickyElementTop = parseInt(stickyFilter.style.top.replace(`px;`, ``))
            if (stickyElementHeight + topGap + gap > screenHeight) {
                if (window.scrollY < currPos) {
                    if (stickyElementTop < topGap + headerOffset) {
                        stickyFilter.style.top = (stickyElementTop + currPos - window.scrollY) + `px`
                    } else if (stickyElementTop >= topGap + headerOffset && stickyElementTop != topGap + headerOffset) {
                        stickyFilter.style.top = `${topGap + headerOffset}px`
                    }
                } else {
                    if (stickyElementTop > endScroll) {
                        stickyFilter.style.top = (stickyElementTop + currPos - window.scrollY) + `px`
                    } else if (stickyElementTop < (endScroll) && stickyElementTop != endScroll) {
                        stickyFilter.style.top = `${endScroll}px`
                    }
                }
            } else {
                stickyFilter.style.top = `${topGap + headerOffset}px`
            }

            currPos = window.scrollY
        }

        const updateSticky = () => {
            screenHeight = window.innerHeight
            stickyElementHeight = stickyFilter.offsetHeight
            positionStickySidebar()
        }

        const updateStickyAfterResize = () => {
            currPos = window.scrollY
            updateSticky()
        }

        window.addEventListener("resize", updateStickyAfterResize)
        document.addEventListener("updateFilterSticky", updateStickyAfterResize)
        document.addEventListener("scroll", updateSticky, { capture: true, passive: true })
    })();
}
