const controller = new AbortController();
const { signal } = controller;
export const AppSearch = () => ({
    open: false,
    isLoading: false,
    search: '',
    results: [],

    get isFocused() {
        return document.body.classList.contains('is-searchOpened')
    },
    get groupedResults() {
        const groups = [
            { type: 'offers', title: this.$el?.dataset.offersLabel ?? 'Nabídky', items: [] },
            { type: 'post', title: this.$el?.dataset.blogLabel ?? 'Magazín', items: [] },
        ]

        this.results.forEach(result => groups.find(e => e.type === result.subtype)?.items.push(result))
        return groups
    },

    async loadResults(term = '') {
        const searchApi = `/wp-json/wp/v2/search/?search=${term}&subtype[]=post&subtype[]=offers&wpessid`
        this.isLoading = true;

        //controller.abort() TODO
        if (term.length > 1) {
            await fetch(`${searchApi}`, {signal})
                .then((response) => response.json())
                .then((data) => {
                    this.results = data;
                    this.open = true;
                })
                .catch((e) => {
                    if (e.name === "AbortError") return; // We know it's been canceled!
                    console.log(e)
                })
                .finally(() => { this.isLoading = false })
        } else {
            this.closeSearch(true)
            this.results = [];
        }
    },

    closeSearch(soft = false) {
        if (!soft) {
            document.body.classList.remove('is-searchOpened')
            document.activeElement?.blur()
        }

        this.open = false
    },

    searchInput: {
        // ['x-model']() { return this.search }, ??? WTF ???
        ['@input.debounce.400ms']() { this.loadResults(this.search) },
        ['@focus']() { document.body.classList.add('is-searchOpened') },
        ['@click.outside']() { this.isFocused && this.closeSearch() }
    },
    searchCloser: {
        ['@click']() { this.closeSearch() }
    },
    searchResults: {
        ['x-show']() { return this.open }
    },
    searchResult: {
        [':href']() { return this.result.url },
        [':class']() { return `AppSearchResult--${this.result.subtype}` }
    },
    searchShowMore: {
        [':href']() {
            return this.search.length
                ? `${this.$el.dataset.url}?${this.$el.dataset.slug}=${this.search}` : this.$el.dataset.url
        }
    }
})