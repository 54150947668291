export const FormRange = () => ({
    rangeEl: null,
    disabled: false,
    modelValue: 0,
    syncInputValue() { this.modelValue = this.rangeEl.valueAsNumber },
    init() {
        this.rangeEl = this.$refs.range
        this.rangeEl?.addEventListener('input', () => this.syncInputValue())
        this.$nextTick(() => this.syncInputValue())
    },
    FormRangeRoot: {
        [':class']() { return this.disabled && 'is-disabled' },
        ['x-on:range-change.window']() {
            this.$nextTick(() => {
                ((this.$event.detail?.name ?? null) === this.rangeEl.name) && this.syncInputValue()
                this.disabled = this.rangeEl.disabled
            })
        },
    },
    FormRangeIndicator: {
        [':style']() { return {
            '--FormRangeIndicatorWidth': `${100 / this.rangeEl.max * (this.modelValue * this.rangeEl.step)}%`,
        }},
    }
})