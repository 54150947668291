import Alpine from "alpinejs";

export const FavoriteLinkToggle = (offerId) => ({
    id: offerId,
    get isActive() { return Alpine.store('Collections').includes(this.id, 'favorite') },
    toggle: {
        [':class']() { return this.isActive && 'is-active' },
        ['@click.prevent']() {
            const targetUrl = this.$el.dataset.url ?? null
            this.isActive && targetUrl ? window.location.assign(targetUrl)
                : Alpine.store('Collections').set(this.id, 'favorite')
        },
    },
    label: {
        ['x-text']() { return this.isActive ? this.$el.dataset.link : this.$el.dataset.label },
    }
})

export const FavoriteList = () => ({
    items: [],
    filterUrl: Alpine.$persist('').as('offersFilterUrl').using(sessionStorage),

    get _mode() { return window.location.search.includes('nabidky') ? 'share' : 'collection' },
    get length() { return this.items.length },
    init() {
        this.items = favoriteData ?? []
        Alpine.store('Collections').favorite?.forEach(collectionItem => {
            !this.items.find(item => item.ID === collectionItem)
                && Alpine.store('Collections').set(collectionItem, 'favorite')
        })
    },

    list: {
        ['x-show']() { return this.items.length }
    },
    item: {
        ['x-show']() { return this.items.find(item => item.ID === parseInt(this.$el.dataset.id)) ?? false }
    },
    removeItem: {
        ['@click.prevent']() {
            console.log('co je vole', parseInt(this.$el.dataset.id))
            this.items = this.items.filter(item => item.ID !== parseInt(this.$el.dataset.id))

            this._mode !== 'share' ?
                Alpine.store('Collections').set(parseInt(this.$el.dataset.id), 'favorite') : this.changeUrl()
        }
    },
    removeAllItems: {
        ['@click.prevent']() {
            this.items = []
            this._mode !== 'share' ? Alpine.store('Collections').clear('favorite') : this.changeUrl()
        }
    },
    shareFavoriteLink: {
        ['@click.prevent']() {
            const url = `${window.location.origin}${window.location.pathname}?${this.collectionQuery}`;
            navigator.clipboard.writeText(url).then(() => {
                this.$el.classList.add('is-copied')
                setTimeout(() => this.$el.classList.remove('is-copied'), 20000)
            }, () => {
                console.error('Nepovedlo se zapsat URL do clipboardu. :-(')
            })
        }
    },
    get collectionQuery() {
        const itemsID = this.items.reduce((stack, item) => [...stack, item.ID], [])
        const query = new URLSearchParams({ 'nabidky': itemsID.join(',') })
        return decodeURIComponent(query.toString())
    },
    changeUrl() {
        window.history.replaceState(null, null, `?${this.collectionQuery}`);
    }
})

export const FavoriteToggle = {
    ':class'() { return Alpine.store('Collections').includes(parseInt(this.$el.dataset.id), 'favorite') && 'is-active' },
    '@click.prevent'() {
        Alpine.store('Collections').set(parseInt(this.$el.dataset.id), 'favorite')
        Alpine.store('Collections').includes(parseInt(this.$el.dataset.id), 'favorite')
            && this.$tooltip(document.querySelector('[data-favorite-tooltip]')?.innerHTML, {
                timeout: 4000,
                allowHTML: true,
                interactive: true,
                appendTo: () => document.querySelector('main'),
                zIndex: 4
            })
    }
}