export const MegaMenu = () => ({
    open: false,
    activeSubMenu: '',
    indicatorStyle: { position: 0, size: 0 },
    isMobile: false,
    isDynamicNav: true,
    isActive(el) { return this.activeSubMenu === el.dataset.name },
    setActive(el) {
        const subEl = el ?? this.$refs?.MegaMenuDefaultSub ?? null

        this.activeSubMenu = subEl?.dataset.name ?? ''
        this.indicatorStyle.position = subEl ? subEl[!this.isMobile ? 'offsetTop' : 'offsetLeft'] : 0
        this.indicatorStyle.size = subEl ? subEl[!this.isMobile ? 'offsetHeight' : 'offsetWidth'] : 0
    },
    init() {
        this.isMobile = document.body.classList.contains('is-mobile') || window.innerWidth < 1280
        this.isDynamicNav = !this.$root.classList.contains('AppMegaMenu')
        this.setActive(null)

        const subMenuHolder = this.$el.querySelector('[x-bind="MegaMenuHolder"]'),
            subMenus = this.$el.querySelectorAll('[x-bind="MegaMenuSub"]')

        !this.isMobile && this.isDynamicNav
        && subMenus.forEach(submenu => {
            submenu.dataset.name = submenu.parentElement.dataset.name
            subMenuHolder?.append(submenu)
        })

        this.$root.parentElement.addEventListener('mouseleave', () => {
            setTimeout(() => this.setActive(null), 400)
        })
    },
    MegaMenuRoot: {
        [':class']() { return this.open && 'is-opened' },
        ['x-on:open-megamenu.window']() { this.open = this.$root === this.$event.detail?.menu }
    },
    MegaMenuHolder: {},
    MegaMenuSubToggle: {
        [':class']() { return this.isActive(this.$el) && 'is-active' },
        ['@click']() { this.$el.type === 'button' && this.setActive(this.$el) },
        ['@mouseover']() { this.setActive(this.$el) },
    },
    MegaMenuSub: {
        ['x-show']() { return this.isActive(this.$el) }
    },
    MegaMenuIndicator: {
        [':style']() { return {
            '--NavMegaMenuIndicator-position': `${this.indicatorStyle.position}px`,
            '--NavMegaMenuIndicator-size': `${this.indicatorStyle.size}px`
        }},
    }
})

export const MegaMenuOpener = {
    ['@click']() { this.$dispatch('open-megamenu', {
        title: this.$el.previousElementSibling?.textContent ?? null,
        menu: this.$el.nextElementSibling
    }) }
}

export const MegaMenuSubHeader = {
    ['@click']() { this.$dispatch('open-megamenu') },
    ['x-on:open-megamenu.window']() {
        const title = this.$event.detail?.title ?? null
        this.$el.classList.toggle('is-visible', title)
        if (title) this.$el.textContent = title
    }
}