export const Modal = () => ({
    id: null,
    modal: null,
    init() {
        this.modal = this.$el
        this.id = this.modal.getAttribute('id')
    },
    openModal() {
        document.body.classList.add('u-hiddenOverflow')
        this.modal.showModal()
    },

    ModalInner: {
        ['x-on:open-modal.window']() { this.id === this.$event.detail.id && this.openModal() },
        ['@close']() { document.body.classList.remove('u-hiddenOverflow') },
        ['@click'](ev) { (ev.target === this.modal) && this.modal.close() },
    },
    ModalCloser: {
        ['@click.prevent']() { this.modal.close() }
    }
})

export const ModalOpener = {
    '@click.prevent'() {
        const targetModal = this.$el.dataset.modalTarget
        targetModal && this.$dispatch('open-modal', { id: `Modal-${targetModal}` })
    }
}