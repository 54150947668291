import Swiper, {Autoplay, Navigation, Pagination} from 'swiper'

export default function () {
    (() => { // home hero swiper
        const slider = document.querySelector('[data-home-swiper]') as HTMLElement;
        if (!slider) return;

        const homeSwiper = new Swiper(slider, {
            allowTouchMove: document.body.classList.contains('is-mobile'),
            cssMode: true,
            slidesPerView: 1,
            modules: [ Autoplay, Pagination ],
            autoplay: {
                delay: 8000,
            },
            pagination: {
                el: '[data-swiper-pagination]',
                type: 'bullets',
                bulletActiveClass: 'is-active',
                clickable: true
            },
        });
    })();

    (() => { // home MINI promo swiper
        const promoSection = document.querySelector('[data-promo-swiper]') as HTMLElement;
        if (!promoSection) return;

        const promoSwiper = new Swiper(promoSection, {
            allowTouchMove: document.body.classList.contains('is-mobile'),
            cssMode: true,
            slidesPerView: 1,
            modules: [ Navigation, Pagination ],
            pagination: {
                el: '[data-promo-pagination]',
                type: 'bullets',
                bulletActiveClass: 'is-active',
                clickable: true
            },
            navigation: {
                nextEl: '[data-promo-next]',
                prevEl: '[data-promo-prev]',
            },
        });
    })();
}
